.headerNavlink{
    white-space: nowrap;
}

#root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.app-main {
    flex: 1;
    padding: 60px 20px 20px;
}

.logo {
    width: 1000px;
    height: 1000px;
}

div.text1{
    margin-left: 1rem;
    /* color: #fbca33; */
    color: #ffffff;
    font-Weight: bold;
    font-Size: 20px;
}

div.text2{
    margin-left: 1rem;
    color: #FFFFFF;
    font-Weight: bold;
}